@import "shared/assets/styles";

.wrapper {
  position: fixed;
  top: 72px;
  right: 24px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.notification {
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  padding: 12px;
  max-width: 300px;
  background-color: $white;
  box-shadow: $shadow;

  &-close {
    padding-right: 40px;
  }
}

.icon {
  --size: 24px;

  position: relative;
  width: var(--size);
  height: var(--size);

  svg {
    width: 24px;
    height: 24px;
  }
}

.error svg {
  fill: $red;
}

.warning svg {
  fill: $orange-yellow;
}

.success svg {
  fill: $green;
}

.info svg {
  fill: $gray-40;
}

.message {
  color: $gray-60;
  line-height: 150%;
  text-align: left;
  max-width: 244px;
  text-wrap: wrap;
}

.close {
  --size: 16px;

  width: var(--size);
  height: var(--size);
  vertical-align: top;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;

  svg {
    width: var(--size);
    height: var(--size);
    fill: $gray-40;
  }
}

.slide {
  &-enter {
    top: -300px;
  }

  &-enter-active {
    top: 0;
    transition: all 0.4s;
  }

  &-exit {
    top: 0;
  }

  &-exit-active {
    top: -300px;
    transition: all 0.4s;
  }
}
