@import "shared/assets/styles";

.container {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  width: fit-content;

  &-big {
    gap: 8px;
  }

  &-medium {
    gap: 6px;
  }

  &-small {
    gap: 4px;
  }
}

.full-width {
  width: 100%;
}

.icon {
  position: relative;
  display: flex;
  align-items: center;

  svg {
    fill: currentcolor;
  }

  &-big {
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &-medium {
    width: 14px;
    height: 14px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  &-small {
    width: 12px;
    height: 12px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.default-button {
  border-radius: 8px;
  transition: 0.3s;
  cursor: pointer;

  &-size {
    &-big {
      @include font-lg(700);

      padding: 12px 20px;
      line-height: 100%;
    }

    &-medium {
      @include font-lg(700);

      padding: 8px 16px;
      line-height: 100%;
    }

    &-small {
      @include font-md(700);

      padding: 5px 12px;
      line-height: 100%;
    }
  }

  &-theme {
    &-primary {
      color: $black;
      background-color: $orange-yellow;
      border: 1px solid $orange-yellow;

      &:hover {
        background-color: $orange-yellow-hover;
        border: 1px solid $orange-yellow-hover;
      }

      &:active {
        background-color: $orange-yellow-active;
        border: 1px solid $orange-yellow-active;
      }

      &:disabled,
      &-disabled {
        color: $gray-40;
        background-color: $orange-yellow-disabled;
        border: 1px solid $orange-yellow-disabled;
        pointer-events: none;
      }
    }

    &-secondary {
      color: $gray-80;
      background-color: transparent;
      border: 1px solid $gray-20;

      &:hover {
        background-color: $gray-5;
        border: 1px solid $gray-40;
      }

      &:active {
        color: $black-active;
        background-color: $gray-10;
        border: 1px solid $gray-60;
      }

      &:disabled,
      &-disabled {
        color: $gray-40;
        background-color: transparent;
        border: 1px solid $gray-10;
        pointer-events: none;
      }
    }
  }
}

.text-button {
  border-radius: 8px;
  transition: 0.3s;
  cursor: pointer;

  &-size {
    &-big {
      @include font-lg(600);

      padding: 10px 16px;
      line-height: 150%;
    }

    &-medium {
      @include font-lg(600);

      padding: 6px 14px;
      line-height: 150%;
    }

    &-small {
      @include font-md(600);

      padding: 5px 12px;
      line-height: 120%;
    }
  }

  &-theme {
    &-primary {
      color: $orange-yellow;
      background-color: transparent;
      border: 1px solid transparent;

      &:hover {
        background-color: $orange-yellow-10;
        border: 1px solid $orange-yellow-10;
      }

      &:active {
        color: $orange-yellow-active;
        background-color: $orange-yellow-disabled;
        border: 1px solid $orange-yellow-disabled;
      }

      &:disabled,
      &-disabled {
        color: $orange-yellow-disabled;
        background-color: transparent;
        border: 1px solid transparent;
        pointer-events: none;
      }
    }

    &-secondary {
      color: $gray-80;
      background-color: transparent;
      border: 1px solid transparent;

      &:hover {
        background-color: $gray-5;
        border: 1px solid $gray-5;
      }

      &:active {
        color: $black-active;
        background-color: $gray-10;
        border: 1px solid $gray-10;
      }

      &:disabled,
      &-disabled {
        color: $gray-40;
        background-color: transparent;
        border: 1px solid transparent;
        pointer-events: none;
      }
    }
  }
}

.link-button {
  border-radius: 8px;
  transition: 0.3s;
  cursor: pointer;

  &-size {
    &-big {
      @include font-lg(600);

      line-height: 150%;
    }

    &-medium {
      @include font-lg(600);

      line-height: 150%;
    }

    &-small {
      @include font-md(600);

      line-height: 120%;
    }
  }

  &-theme {
    &-primary {
      color: $orange-yellow;
      background-color: transparent;
      border: 1px solid transparent;

      &:hover {
        color: $orange-yellow-hover;
      }

      &:active {
        color: $orange-yellow-active;
      }

      &:disabled,
      &-disabled {
        color: $orange-yellow-disabled;
        pointer-events: none;
      }
    }

    &-secondary {
      color: $blue;
      background-color: transparent;
      border: 1px solid transparent;

      &:hover {
        color: $blue-hover;
      }

      &:active {
        color: $blue-active;
      }

      &:disabled,
      &-disabled {
        color: $blue-disabled;
        pointer-events: none;
      }
    }
  }
}

.icon-button {
  &-size {
    &-big {
      padding: 11px;
    }

    &-medium {
      padding: 8px;
    }

    &-small {
      padding: 5px;
    }
  }
}
