@import "shared/assets/styles";

.size {
  &-xl {
    @include font-xl(400);
  }

  &-lg {
    @include font-lg(400);
  }

  &-md {
    @include font-md(400);
  }

  &-sm {
    @include font-sm(400);
  }

  &-xs {
    @include font-xs(400);
  }
}

.weight {
  &-extra {
    font-weight: 900;
  }

  &-semi {
    font-weight: 700;
  }

  &-regular {
    font-weight: 400;
  }
}

.line-height {
  &-small {
    line-height: 100%;
  }

  &-normal {
    line-height: 120%;
  }

  &-big {
    line-height: 150%;
  }
}

.align {
  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  &-justify {
    text-align: justify;
  }
}
