@import "shared/assets/styles";

.link {
  display: flex;
  align-items: center;
  gap: 6px;
  text-decoration: none !important;

  @include font-lg(700);

  font-weight: 700 !important;

  .icon {
    width: 16px;
    height: 16px;
    fill: $gray-80;
  }
}
