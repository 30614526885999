@import "shared/assets/styles";

.container {
  width: 100%;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;

  @include desktop {
    max-width: 1140px;
  }

  @include laptop {
    max-width: 960px;
  }

  @include tablet {
    max-width: 720px;
  }

  @include small-tablet {
    max-width: 540px;
  }

  @include phone {
    max-width: none;
  }

  &.fluid {
    max-width: none;
  }
}
