@import "shared/assets/styles";

.navigation {
  display: flex;
  flex-direction: column;
  gap: 6px;

  @include tablet {
    width: 100%;
    overflow: auto;
    gap: 12px;
    flex-direction: row;
    align-items: center;
  }
}

.link {
  display: flex;
  padding: 7px 8px;
  gap: 6px;
  width: 100%;
  white-space: nowrap;

  svg {
    width: 18px;
    height: 18px;
    fill: currentcolor;
  }

  @include tablet {
    width: fit-content;
  }
}

.exit {
  margin-top: 18px;

  @include tablet {
    margin-top: 0;
    margin-left: 12px;
  }
}

.confirm {
  .icon {
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
  }
}
