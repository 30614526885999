@import "shared/assets/styles";

.overflow {
  overflow: hidden;
}

.container {
  --header: 50px;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: calc(100vh - var(--header));
  margin-top: var(--header);
  padding-bottom: env(safe-area-inset-bottom);

  &.light {
    background-color: rgba($black, 0.25);
  }
}

.content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 540px;
  overflow-y: auto;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.dark {
  .content {
    color: $white;
    background-color: $black;
  }
}

.light {
  .content {
    color: $black;
    background-color: $white;
  }
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.drawer {
  &-enter {
    &.light {
      background-color: transparent;
    }

    .left {
      &.content {
        left: -100%;
      }
    }

    .right {
      &.content {
        right: -100vw;
      }
    }
  }

  &-enter-active {
    &.light {
      background-color: rgba($black, 0.25);
      transition: background-color 0.4s;
    }

    .left {
      &.content {
        left: 0;
        transition: left 0.4s;
      }
    }

    .right {
      &.content {
        right: 0;
        transition: right 0.4s;
      }
    }
  }

  &-exit {
    &.light {
      background-color: rgba($black, 0.25);
    }

    .left {
      &.content {
        left: 0;
      }
    }

    .right {
      &.content {
        right: 0;
      }
    }
  }

  &-exit-active {
    &.light {
      background-color: transparent;
      transition: background-color 0.4s;
    }

    .left {
      &.content {
        left: -100%;
        transition: left 0.4s;
      }
    }

    .right {
      &.content {
        right: -100vw;
        transition: right 0.4s;
      }
    }
  }
}
