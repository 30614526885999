$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

@mixin phone {
  @media (max-width: $sm) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: $md) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $lg) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: $xl) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: $xxl) {
    @content;
  }
}
