@import "shared/assets/styles";

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 50px;
  background-color: $black;
}

.content {
  height: 100%;
  max-width: 1320px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 20px;

  @include phone {
    gap: 0;
  }
}

.left {
  display: flex;
  align-items: stretch;
  height: 100%;

  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 20px;

    img {
      height: auto;
    }

    @include small-tablet {
      margin-right: 0;
    }
  }

  .burger {
    display: none;
    margin-right: 12px;

    svg {
      width: 24px;
      height: 24px;
      fill: $white;
    }

    @include small-tablet {
      display: flex;
    }
  }

  @include small-tablet {
    align-items: center;
  }
}

.right {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  .account {
    color: $orange-yellow;
    max-width: 280px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: none;

    @include font-lg(700);

    @include laptop {
      max-width: 180px;
    }

    .name {
      @include tablet {
        display: none;
      }
    }

    svg {
      display: none;
      width: 24px;
      height: 24px;
      fill: $orange-yellow;
      flex-shrink: 0;

      @include tablet {
        display: block;
      }
    }
  }
}

.profile-info {
  position: relative;
  padding-top: 20px;

  .text {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    color: $white;
    text-align: left;

    &-with-arrow {
      justify-content: space-between;
    }

    .name {
      display: flex;
      align-items: center;
      gap: 6px;

      .profile {
        color: $orange-yellow;
        font-size: 20px;
      }
    }

    .arrow {
      color: $orange-yellow;
      font-size: 24px;
    }
  }

  .link {
    color: $orange-yellow;
    gap: 6px;
    text-decoration: none;

    @include font-xl(700);
  }
}
