@import "shared/assets/styles";

.link {
  transition: color 0.3s;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: $orange-yellow;
  text-decoration: underline;
}

.blue {
  color: $blue;

  &:hover {
    color: $blue-hover;
  }

  &:active,
  &:focus,
  &:active:focus {
    color: $blue-active;
  }
}

.black {
  color: $black;

  &:hover {
    color: $gray-80;
  }

  &:active,
  &:focus,
  &:active:focus {
    color: $black-active;
  }
}
