@import "shared/assets/styles";

.confirm {
  width: 300px;

  &-img {
    max-width: 100%;

    img {
      display: block;
      margin: auto;
    }

    svg {
      width: 48px;
      height: 48px;
      display: block;
      margin: auto;
    }
  }

  &-content {
    margin-top: 12px;
  }

  &-title {
    margin-top: 12px;

    @include font-xl(700);

    line-height: 150%;
  }

  &-header {
    padding-top: 12px;
    padding-bottom: 6px;
    border-bottom: none;
  }

  &-body {
    padding-top: 6px;
    padding-bottom: 16px;
    text-align: center;
  }

  &-footer {
    justify-content: center;
    border-top: none;
  }
}
