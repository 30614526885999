@import "shared/assets/styles";

.burger {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 24px;
    flex-shrink: 0;

    &.close {
      color: $white;
    }
  }
}
