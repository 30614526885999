@import "shared/assets/styles";

.link {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  color: $white;
  text-decoration: none;
  text-align: center;
  transition: color 0.3s;

  @include font-lg(400);

  line-height: 100%;

  &.active {
    color: $orange-yellow;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: $orange-yellow;
    }
  }

  &:hover {
    color: $orange-yellow;
  }

  @include small-tablet {
    height: fit-content;
    padding: 13px 24px;
  }
}

.profile {
  width: fit-content;
  color: $gray-80;
  border-radius: 8px;
  transition: background-color 0.3s;

  @include font-lg(400);

  &:hover {
    color: $gray-80;
    background-color: $gray-5;
  }

  &.active {
    color: $orange-yellow;
    background-color: $orange-yellow-10;

    @include font-lg(400);

    &::after {
      content: none;
    }
  }
}
