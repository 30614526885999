@import "shared/assets/styles";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-decoration: none;
  justify-content: center;
  text-align: center;

  @include font-lg(300);

  @include small-tablet {
    align-items: start;
    justify-content: flex-start;
    height: fit-content;
    width: inherit;
  }
}

.label {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  color: $white;
  transition: color 0.3s;
  cursor: pointer;

  .icon {
    fill: currentcolor;
    display: block;
    flex-shrink: 0;
    font-size: 14px;
    transform-origin: center;
    transition:
      fill 0.3s,
      transform 0.3s;

    @include small-tablet {
      font-size: 24px;
    }
  }

  &:hover {
    color: $orange-yellow;
  }

  &.open {
    color: $orange-yellow;

    .icon {
      transform: rotate(180deg);
    }
  }

  &.active {
    @include font-lg(500);

    color: $orange-yellow;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: $orange-yellow;

      @include small-tablet {
        content: none;
      }
    }
  }

  @include small-tablet {
    width: 100%;
    padding: 8px 24px;

    .icon {
      margin-left: auto;
    }
  }
}

.content {
  position: absolute;
  display: flex;
  top: 50px;
  left: -24px;
  flex-direction: column;
  background-color: $black;
  width: max-content;
  text-align: start;

  .link {
    padding: 13px 24px;
    color: $white;
    background-color: transparent;
    text-decoration: none;
    text-align: left;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    gap: 4px;

    @include font-lg(300);

    line-height: 100%;

    &:hover {
      background-color: $gray-80;
    }

    @include small-tablet {
      padding: 13px 40px;
    }

    span {
      font-size: inherit;
      color: $orange-yellow;
    }
  }

  @include small-tablet {
    position: relative;
    top: auto;
    left: 0;
    width: 100%;
  }
}

.count {
  @include font-sm(700);

  background-color: $red;
  padding: 2px 4px;
  border-radius: 8px;
  line-height: 100%;
  color: $white;
}
