$orange-yellow: #ffb600;
$orange-yellow-hover: #ffc533;
$orange-yellow-active: #f4af02;
$orange-yellow-disabled: #fff0cc;
$orange-yellow-10: #fff8e6;
$orange: #ff8a00;
$orange-10: #fff3e6;
$black: #242424;
$black-active: #000;
$gray-80: #505050;
$gray-60: #7c7c7c;
$gray-40: #a7a7a7;
$gray-30: #bdbdbd;
$gray-20: #d3d3d3;
$gray-10: #e9e9e9;
$gray-5: #f4f4f4;
$gray-background: #f9f9f9;
$white: #fff;
$red: #ff3a3a;
$red-10: #ffebeb;
$green: #3bd520;
$green-disabled: #c4f2bc;
$green-10: #ebfbe9;
$yellow: #ffdc22;
$yellow-10: #fffbe9;
$blue: #00b2ff;
$blue-active: #00a0e6;
$blue-hover: #33c2ff;
$blue-disabled: #ccf0ff;
$blue-10: #e6f7ff;
$dark-blue: #06f;
$dark-blue-10: #e6f0ff;
$purple: #a500f2;
$purple-10: #f6e6fe;
$pink: #ff4ced;
$pink-10: #ffedfd;
