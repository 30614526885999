@mixin font-family {
  @font-face {
    font-family: Roboto;
    src:
      local("Roboto Regular"),
      local("Roboto-Regular"),
      url("../fonts/Roboto-Regular.woff2") format("woff2"),
      url("../fonts/Roboto-Regular.woff") format("woff"),
      url("../fonts/Roboto-Regular.svg#Roboto-Regular") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: Roboto;
    src:
      local("Roboto Bold"),
      local("Roboto-Bold"),
      url("../fonts/Roboto-Bold.woff2") format("woff2"),
      url("../fonts/Roboto-Bold.woff") format("woff"),
      url("../fonts/Roboto-Bold.svg#Roboto-Bold") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: Roboto;
    src:
      local("Roboto Black"),
      local("Roboto-Black"),
      url("../fonts/Roboto-Black.woff2") format("woff2"),
      url("../fonts/Roboto-Black.woff") format("woff"),
      url("../fonts/Roboto-Black.svg#Roboto-Black") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
}

@mixin font-xs($weight) {
  font-size: 8px;
  font-weight: $weight;
  font-style: normal;
  line-height: normal;
}

@mixin font-sm($weight) {
  font-size: 10px;
  font-weight: $weight;
  font-style: normal;
  line-height: normal;
}

@mixin font-md($weight) {
  font-size: 12px;
  font-weight: $weight;
  font-style: normal;
  line-height: normal;
}

@mixin font-lg($weight) {
  font-size: 14px;
  font-weight: $weight;
  font-style: normal;
  line-height: normal;
}

@mixin font-xl($weight) {
  font-size: 16px;
  font-weight: $weight;
  font-style: normal;
  line-height: normal;
}

@mixin font-heading($size) {
  font-size: $size;
  font-style: normal;
  line-height: 120%;
}
