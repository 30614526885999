@import "shared/assets/styles";

@mixin grid-classes($bp: "") {
  $inner-bp: $bp;

  @if $bp != "" {
    $inner-bp: "-#{$bp}";

    --row-gap: var(--row#{$inner-bp}-gap) !important;
    --column-gap: var(--column#{$inner-bp}-gap) !important;
  }

  &#{$inner-bp}-no-wrap {
    --wrap: nowrap;
  }

  &#{$inner-bp}-align-start {
    --flex-align: start;
  }

  &#{$inner-bp}-align-end {
    --flex-align: flex-end;
  }

  &#{$inner-bp}-align-center {
    --flex-align: center;
  }

  &#{$inner-bp}-justify-end {
    --flex-justify: flex-end;
  }

  &#{$inner-bp}-justify-center {
    --flex-justify: center;
  }

  &#{$inner-bp}-justify-between {
    --flex-justify: space-between;
  }

  &#{$inner-bp}-justify-around {
    --flex-justify: space-around;
  }

  &#{$inner-bp}-justify-evenly {
    --flex-justify: space-evenly;
  }

  &#{$inner-bp}-direction-row-reverse {
    --direction: row-reverse;
  }

  &#{$inner-bp}-direction-column {
    --direction: column;
  }

  &#{$inner-bp}-direction-column-reverse {
    --direction: column-reverse;
  }
}

@mixin col-classes($bp: "") {
  $columns: 12;
  $column-width: calc(100% / $columns);
  $inner-bp: $bp;

  @if $bp != "" {
    $inner-bp: "-#{$bp}";
  }

  &#{$inner-bp}-align-right {
    --text-align: right;
  }

  &#{$inner-bp}-align-center {
    --text-align: center;
  }

  &#{$inner-bp}-align-justify {
    --text-align: justify;
  }

  &#{$inner-bp}-valign-start {
    --flex-align-self: flex-start;
  }

  &#{$inner-bp}-valign-end {
    --flex-align-self: flex-end;
  }

  &#{$inner-bp}-valign-center {
    --flex-align-self: center;
  }

  &#{$inner-bp}-valign-stretch {
    --flex-align-self: stretch;
  }

  &#{$inner-bp}-valign-start,
  &#{$inner-bp}-valign-end,
  &#{$inner-bp}-valign-center,
  &#{$inner-bp}-valign-stretch {
    align-self: var(--flex-align-self);
  }

  @for $i from -1 through $columns {
    &#{$inner-bp}-order-#{$i} {
      --order: #{$i};
    }

    @if $i >= 0 {
      &#{$inner-bp}-offset-#{$i} {
        --offset: #{$column-width * $i};
      }
    }

    @if $i >= 1 {
      &#{$inner-bp}-span-#{$i} {
        --flex: 0 0 auto;
        --width: #{$column-width * $i};
      }
    }
  }
}

.grid {
  --flex-align: stretch;
  --flex-justify: flex-start;
  --direction: row;
  --wrap: wrap;
  --row-gap: 0;
  --column-gap: 0;

  display: flex;
  flex-flow: var(--direction) var(--wrap);
  align-items: var(--flex-align);
  justify-content: var(--flex-justify);
  row-gap: var(--row-gap);
  margin-left: calc(var(--column-gap) / -2);
  margin-right: calc(var(--column-gap) / -2);
  box-sizing: border-box;

  @include grid-classes;

  @include desktop {
    @include grid-classes("xxl");
  }

  @include laptop {
    @include grid-classes("xl");
  }

  @include tablet {
    @include grid-classes("lg");
  }

  @include small-tablet {
    @include grid-classes("md");
  }

  @include phone {
    @include grid-classes("sm");
  }

  .col {
    --flex-align-self: normal;
    --text-align: left;
    --offset: 0;
    --order: 0;
    --width: auto;
    --flex: 1 0 0;

    flex: var(--flex);
    width: var(--width);
    max-width: 100%;
    margin-left: var(--offset);
    padding-left: calc(var(--column-gap) / 2);
    padding-right: calc(var(--column-gap) / 2);
    order: var(--order);
    text-align: var(--text-align);
    box-sizing: border-box;

    @include col-classes;

    @include desktop {
      @include col-classes("xxl");
    }

    @include laptop {
      @include col-classes("xl");
    }

    @include tablet {
      @include col-classes("lg");
    }

    @include small-tablet {
      @include col-classes("md");
    }

    @include phone {
      @include col-classes("sm");
    }
  }
}
