@import "shared/assets/styles";

.heading {
  margin: 0;
  overflow-wrap: anywhere;
  max-width: 100%;
  display: flex;
  align-items: center;

  .link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    flex-shrink: 0;
    text-decoration: none;
  }

  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .label {
    color: $orange-yellow;

    @include small-tablet {
      display: block;
    }
  }
}

.flex {
  display: flex;
  align-items: start;
  justify-content: flex-start;
}

.h1 {
  @include font-heading(32px);

  .link,
  .content {
    margin-right: 18px;
  }

  .link {
    margin-top: 2px;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .icon {
    width: 40px;
    height: 50px;
  }

  .container {
    margin-top: 6px;
  }

  @include small-tablet {
    @include font-heading(24px);

    .link,
    .content {
      margin-right: 12px;
    }

    .link {
      margin-top: 1px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .icon {
      width: 26px;
      height: 32px;
    }

    .container {
      margin-top: 2px;
    }
  }
}

.h2 {
  @include font-heading(24px);

  .link,
  .content {
    margin-right: 12px;
  }

  .link {
    margin-top: 1px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .icon {
    width: 26px;
    height: 32px;
  }

  .container {
    margin-top: 2px;
  }

  @include small-tablet {
    @include font-heading(16px);

    .link,
    .content {
      margin-right: 6px;
    }

    .link {
      margin-top: 0;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .icon {
      width: 20px;
      height: 24px;
    }

    .container {
      margin-top: 3px;
    }
  }
}

.h3 {
  @include font-heading(16px);

  .link,
  .content {
    margin-right: 6px;
  }

  .link {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .icon {
    width: 20px;
    height: 24px;
  }

  .container {
    margin-top: 3px;
  }
}

.extra-bold {
  font-weight: 900;
}

.semi-bold {
  font-weight: 700;
}
